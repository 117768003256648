/* grid container */
.container {
  display: grid;

  grid-template-areas:
    "header header header"
    "nav content content"
    "footer footer footer";

  grid-template-columns: auto 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  grid-gap: 10px;

  height: 100vh;
  background-color: rgb(243, 244, 246);
}

/* assign columns to grid areas */
header {
  grid-area: header;
}

main {
  grid-area: content;
}

nav {
  grid-area: nav;
}

footer {
  grid-area: footer;
}

@media (max-width: 768px) {
  .container {
    grid-template-areas:
      "header"
      "content"
      "footer";

    grid-template-columns: 1fr;
    grid-template-rows:
      auto
      1fr
      auto
  }

  nav {
    display: none;
  }

  main {
    height: 100vh;
    width: 100vh;
  }
}